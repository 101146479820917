import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';


import Spinner from 'react-bootstrap/Spinner';

import Button from '@mui/material/Button';


import Footer from '../components/footer';

import DrawerComponent from '../components/Drawer';

import new_testament from '../resources/new_testament.json';
import old_testament from '../resources/old_testament.json';



const SearchPage = () => {
    const location = useLocation();
    const searchCriteria = location.state;
    console.log('SearchPage searchCriteria: ', searchCriteria);

    const navigate = useNavigate();

    // <string name="niv">NIV</string>
    // <string name="kjv">KJV</string>
    // <string name="nasb">NASB</string>
    // <string name="hb5">HB5</string>
    // <string name="lzz">呂振中</string>
    // <string name="none">全中文</string>
    // <string name="cuv">和合本</string>
    // <string name="ncv">新譯本</string>

    const [niv, setNiv] = useState([]);
    const [kjv, setKjv] = useState([]);
    const [nasb, setNasb] = useState([]);
    const [cuv, setCuv] = useState([]);
    const [loading, setLoading] = useState(true);
    const [combinedVerses, setCombinedVerses] = useState([]);
    const handleRedirectItemClick = (input) => {

        var target_obj;

        var combinedVerses = [...old_testament, ...new_testament];

        console.log('handleRedirectItemClick: combinedVerses:', combinedVerses);
        console.log('handleRedirectItemClick: input:', input);

        //[LZZ]gen 9:5
        const textArray = input.split(":");
        var abbr = (textArray[0].split(']')[1]).split(' ')[0];
        var chapter = (textArray[0].split(']')[1]).split(' ')[1].trim();

        console.log('handleRedirectItemClick: abbr:', abbr);

        combinedVerses.forEach(item => {
            if (item.abbr.toLocaleLowerCase() === abbr.toLocaleLowerCase()) {
                target_obj = item;
            }
        });

        console.log('target_obj:', target_obj);
        console.log('chapter:', chapter);



        var target = { ...target_obj, 'selectedChapter': chapter };

        console.log('handleRedirectItemClick: target:', target);

        navigate('/display', { state: target });
    };

    const fetchData = async () => {

        try {

            try {
                const response = await axios.post('https://www.zeoinjesus.com/api/search/', {
                //const response = await axios.post('http://localhost:8000/api/search/', {
                    //const response = await axios.post('http://134.122.2.66:8000/api/search/', {
                    versions: searchCriteria.versions,
                    book: searchCriteria.books,
                    keywords: searchCriteria.keywords
                });
                //console.log('test: ', response.data.combinedResults);
                setCombinedVerses(response.data.filteredArray);

            } catch (error) {
                console.error('Error sending data:', error);
            }



            setLoading(false);


        } catch (error) {
            console.error('Error fetching data:', error);
        }


    };

    useEffect(() => {

        //console.log('entered useEffect');

        fetchData();


    }, [location]);




    //handle Previous Chapter
    const handleTitleClick = () => {
        navigate('/');
    };

    const convertSubtitle = (text) => {
        // const textArray = text.split(":");
        // var partA = textArray[0].split(']')[0].replace('[', '');
        // var partB = textArray[0].slice(-2).trim();
        // var partC = textArray[1];

        return filterVersionName(text);
    };

    const getVerseNum = (text) => {
        const textArray = text.split(":");
        return textArray[1];
    };

    const filterVersionName = (version) => {
        var result = version.replace('LZZ', '呂振中').replace('CUV', '和合本').replace('NCV', '新譯本');
        return result;
    };

    const filterSpecialCharacters = (input) => {
        const result = input.toString().replace(/%/g, '\'').replace(/#/g, '"');
        return result;
    }

    // <string name="niv">NIV</string>
    // <string name="kjv">KJV</string>
    // <string name="nasb">NASB</string>
    // <string name="hb5">HB5</string>
    // <string name="lzz">呂振中</string>
    // <string name="none">全中文</string>
    // <string name="cuv">和合本</string>
    // <string name="ncv">新譯本</string>

    const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    if (loading) {
        return (
            <div>
                <div className="grid-container">
                    <div className="grid-item"></div>
                    <div onClick={handleTitleClick} className="grid-item"><h1>搜尋結果</h1></div>
                    <div className="grid-item">
                    </div>
                </div>
                <div><h6>搜尋文字：{searchCriteria.keywords}</h6></div>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div>

            <div className="grid-container">
                <div className="grid-item"></div>
                <div onClick={handleTitleClick} className="grid-item"><h1>搜尋結果</h1></div>
                <div className="grid-item"> </div>
            </div>
            <div><h6>搜尋文字：{searchCriteria.keywords}</h6></div>
            <DrawerComponent />


            {combinedVerses.length !== 0 && combinedVerses.map(item => (
                <Card>
                    <Card.Body style={{ ...getVerseNum(item?._id) % 2 === 1 ? { backgroundColor: "#FFFFFF" } : { backgroundColor: "#FFFFFF" }, ...{ padding: '5px 5px', margin: '0px' } }}>
                        <Card.Subtitle className="mb-1 text-muted">{convertSubtitle(item?._id)}</Card.Subtitle>
                        <Card.Text>
                            {filterSpecialCharacters(item?.text)}
                        </Card.Text>
                        <Button variant="outlined" onClick={() => handleRedirectItemClick(item?._id)} size="small">全文</Button>
                    </Card.Body>
                </Card>
            ))}
            <Footer />
        </div>
    );
};

export default SearchPage;