import React, { useState } from 'react';
import ColorTabs from '../components/Tab';
import Footer from '../components/footer';
import DrawerComponent from '../components/Drawer';

const MainPage = () => {


  return (
    <div>
      <div className="grid-container-for-title">
        <div className="grid-item"></div>
        <div className="grid-item"><h1>聖經 - 中英對照</h1></div>
        <div className="grid-item"></div>
      </div>
      <DrawerComponent />

      <ColorTabs />
      <Footer />
    </div >
  );
};

export default MainPage;