import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Spinner from 'react-bootstrap/Spinner';

import Button from '@mui/material/Button';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Footer from '../components/footer';

import DrawerComponent from '../components/Drawer';

const DisplayPage = () => {

    const [isDev, setIsDev] = useState(() => {
        return localStorage.getItem('dev') || false;
    });

    const location = useLocation();
    const selectedBookAndChapter = location.state;
    //console.log('DisplayPage selectedBookAndChapter: ', selectedBookAndChapter);
    const navigate = useNavigate();

    const [selectedValues, setSelectedValues] = useState(['cuv', 'nasb']);

    const handleSelection = (event, newValues) => {
        setSelectedValues(newValues);

        //console.log('handleSelection: ', newValues);
    };

    const getSelectedValues = () => {
        //console.log('Selected Values:', selectedValues);
        return selectedValues;
    };

    // <string name="niv">NIV</string>
    // <string name="kjv">KJV</string>
    // <string name="nasb">NASB</string>
    // <string name="hb5">HB5</string>
    // <string name="lzz">呂振中</string>
    // <string name="none">全中文</string>
    // <string name="cuv">和合本</string>
    // <string name="ncv">新譯本</string>

    const [niv, setNiv] = useState([]);
    const [kjv, setKjv] = useState([]);
    const [nasb, setNasb] = useState([]);
    const [cuv, setCuv] = useState([]);
    const [loading, setLoading] = useState(true);
    const [combinedVerses, setCombinedVerses] = useState([]);

    // useEffect to observe state changes
    useEffect(() => {
        fetchData();
    }, [selectedValues]); // Dependency array includes 'count'

    const fetchData = async () => {

        // console.log('entered fetchData: ', {
        //     versions: getSelectedValues(),
        //     book: selectedBookAndChapter.abbr,
        //     chapter: selectedBookAndChapter.selectedChapter
        // });

        try {

            try {
                const response = await axios.post('https://www.zeoinjesus.com/api/bible/', {
                    //const response = await axios.post('http://localhost:8000/api/bible/', {
                    //const response = await axios.post('http://134.122.2.66:8000/api/bible/', {
                    versions: getSelectedValues(),
                    book: selectedBookAndChapter.abbr,
                    chapter: selectedBookAndChapter.selectedChapter
                });
                //console.log('test: ', response.data.combinedResults);
                setCombinedVerses(response.data.combinedResults);

            } catch (error) {
                console.error('Error sending data:', error);
            }



            setLoading(false);


        } catch (error) {
            console.error('Error fetching data:', error);
        }


    };

    useEffect(() => {

        //console.log('entered useEffect');

        fetchData();


    }, [location]);



    //handle Next Chapter
    const handleNextChClick = () => {
        if (selectedBookAndChapter.selectedChapter + 1 <= selectedBookAndChapter.chapters) {
            var target = { ...selectedBookAndChapter, 'selectedChapter': selectedBookAndChapter.selectedChapter + 1 };

            //console.log('InteractiveList: target:', target);
            navigate('/display', { state: target });
        }
    };

    //handle Previous Chapter
    const handlePreviousChClick = () => {
        if (selectedBookAndChapter.selectedChapter - 1 > 0) {
            var target = { ...selectedBookAndChapter, 'selectedChapter': selectedBookAndChapter.selectedChapter - 1 };

            //console.log('InteractiveList: target:', target);
            navigate('/display', { state: target });
        }
    };

    //handle Previous Chapter
    const handleTitleClick = () => {
        navigate('/');
    };

    const convertSubtitle = (text) => {
        const textArray = text.split(":");
        var partA = textArray[0].split(']')[0].replace('[', '');
        var partB = textArray[0].slice(-2).trim();
        var partC = textArray[1];

        return filterVersionName(partA) + ' ' + partB + ':' + partC;
    };

    const getVerseNum = (text) => {
        const textArray = text.split(":");
        return textArray[1];
    };

    const filterVersionName = (version) => {
        var result = version.replace('LZZ', '呂振中').replace('CUV', '和合本').replace('NCV', '新譯本');
        return result;
    };

    const filterSpecialCharacters = (input) => {
        const result = input.toString().replace(/%/g, '\'').replace(/#/g, '"');
        return result;
    }

    // <string name="niv">NIV</string>
    // <string name="kjv">KJV</string>
    // <string name="nasb">NASB</string>
    // <string name="hb5">HB5</string>
    // <string name="lzz">呂振中</string>
    // <string name="none">全中文</string>
    // <string name="cuv">和合本</string>
    // <string name="ncv">新譯本</string>

    const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    if (loading) {
        return (
            <div>
                <div className="grid-container">
                    <div className="grid-item"><Button onClick={handlePreviousChClick} startIcon={<ArrowBackIosRoundedIcon />}></Button ></div>
                    <div onClick={handleTitleClick} className="grid-item"><h1>{selectedBookAndChapter.name} Ch{selectedBookAndChapter.selectedChapter}</h1></div>
                    <div className="grid-item"> <Button onClick={handleNextChClick} startIcon={<ArrowForwardIosRoundedIcon />}></Button >
                    </div>
                </div>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div>

            <div className="grid-container">
                <div className="grid-item"><Button onClick={handlePreviousChClick} startIcon={<ArrowBackIosRoundedIcon />}></Button ></div>
                <div onClick={handleTitleClick} className="grid-item"><h1>{selectedBookAndChapter.name} Ch{selectedBookAndChapter.selectedChapter}</h1></div>
                <div className="grid-item"> <Button onClick={handleNextChClick} startIcon={<ArrowForwardIosRoundedIcon />}></Button >
                </div>
            </div>
            <DrawerComponent />

            <div>
                <ToggleButtonGroup
                    value={selectedValues}
                    onChange={handleSelection}
                    aria-label="multi select toggle button"
                >
                    <ToggleButton value="cuv" aria-label="option 1">
                        和合本
                    </ToggleButton>
                    <ToggleButton value="kjv" aria-label="option 2">
                        KJV
                    </ToggleButton>
                    <ToggleButton value="lzz" aria-label="option 3">
                        呂振中
                    </ToggleButton>
                    <ToggleButton value="nasb" aria-label="option 4">
                        NASB
                    </ToggleButton>
                    {isDev &&
                        <ToggleButton value="ncv" aria-label="option 5">
                            新譯本
                        </ToggleButton>
                    }
                    <ToggleButton value="niv" aria-label="option 6">
                        NIV
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            {combinedVerses.length !== 0 && combinedVerses.map(item => (
                <Card>
                    <Card.Body style={{ ...getVerseNum(item?._id) % 2 === 1 ? { backgroundColor: "#FFFFFF" } : { backgroundColor: "#F5F5F5" }, ...{ padding: '5px 5px', margin: '0px' } }}>
                        <Card.Subtitle className="mb-1 text-muted">{convertSubtitle(item?._id)}</Card.Subtitle>
                        <Card.Text>
                            {filterSpecialCharacters(item?.text)}
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}
            <Footer />
        </div>
    );
};

export default DisplayPage;